import * as React from "react"
import { graphql } from "gatsby"
// import { graphql} from "gatsby"
// import { Image } from "@components/atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import SEO from "@components/atoms/Seo"
import PageContent from "@projects/PageContent"


function IndexPage()
{
    return (
        <>
            <SEO subtitle="特定商取引法に基づく表記" />
            <PageContent title="特定商取引法に基づく表記">
                <section>
                    <h4>事業者名</h4>
                    <p>株式会社WONDER&CLOCKS</p>
                </section>
                <section>
                    <h4>代表者</h4>
                    <p>西村洋一</p>
                </section>
                <section>
                    <h4>所在地</h4>
                    <p>【本社】東京都新宿区新宿5-1-1</p>
                </section>
                <section>
                    <h4>お問い合わせ</h4>
                    <p>info@wonder-clocks.com</p>
                </section>
                <section>
                    <h4>販売価格</h4>
                    <p>ホームページに記載(税別)</p>
                </section>
                <section>
                    <h4>商品代金以外の必要料金</h4>
                    <p>銀行振込手数料</p>
                </section>
                <section>
                    <h4>お支払い方法</h4>
                    <p>クレジットカード決済、初回のみ銀行振込</p>
                </section>
                <section>
                    <h4>代金の支払い時期</h4>
                    <p>練習生契約→3ヶ月分(一括前払い)、チケット全般→購入時</p>
                </section>
                <section>
                    <h4>キャンセル、返金について</h4>
                    <p>いかなる場合でも、1度頂戴しました各種費用の返金はできかねます。</p>
                </section>
            </PageContent>
        </>
    )
}

export const pagerQuery = graphql`
    fragment Thumbnail on ImageSharp {
        gatsbyImageData(
            width: 1280
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 80
        )
    }
`
export default IndexPage
