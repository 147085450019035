import React from "react"

import * as css from "@css/projects/p-page-content.module.styl"

export default function PageContent({title,children})
{
    return(
        <div className={css.pPageContent}>
            <div className={css.pPageContent__inner}>
                <h2 className={css.pPageContent__title}>{title}</h2>
                <div className={css.pPageContent__content}>
                    {children}
                </div>
            </div>
        </div>
    )
}
